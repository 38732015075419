import { memo } from 'react';
import { cn } from '@divlab/divanui';

import { open as openModal } from '@Stores/ModalWindows';
import Img from '@UI/Img';
import Header from '@Promo/elems/Header';
import Link from '@Navigation/Link';
import UILink from '@UI/Link';
import { useDeps } from '@Contexts/DI';
import useNavigation from '@Navigation/useNavigation';
import styles from './InlineBannerTemplate.module.css';

import type { InlineBannerTemplateProps } from '@Promo/typings';
import type { FC, HTMLAttributes } from 'react';

interface ContainerProps extends HTMLAttributes<HTMLElement> {
  link?: string;
}

const Container: FC<ContainerProps> = (props) => {
  const { link, children, ...restProps } = props;

  return link ? (
    <Link {...restProps} target='_blank' to={link}>
      {children}
    </Link>
  ) : (
    <div {...restProps}>{children}</div>
  );
};

const InlineBannerTemplate: FC<InlineBannerTemplateProps> = (props) => {
  const { className, banner, categoryName, ...restProps } = props;
  const { pathname } = useNavigation();
  const { analytics } = useDeps();

  const handleClickLink = () => {
    const onboarding = banner.onboarding;

    if (onboarding && onboarding.length) {
      openModal('Onboarding', { steps: onboarding, categoryName });
      analytics.dispatchEvent('categories.onboarding.start', { pathname, categoryName });
    }
  };

  return (
    <Container
      {...restProps}
      className={cn(styles.banner, className, {
        [styles.inversed]: banner.inversed,
        [styles.textAlignTop]: banner.textAlign === 'top',
      })}
      link={banner.link.url}
    >
      <>
        <Img className={styles.image} src={banner.image.desktop.src} />
        <div className={styles.contentWrapper}>
          <Header className={styles.title} text={banner.header} />
          <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: banner.text }} />
          <UILink
            onClick={handleClickLink}
            className={styles.link}
            underlined
            view={banner.inversed ? 'white' : 'primary'}
          >
            {banner.link?.text}
          </UILink>
        </div>
      </>
    </Container>
  );
};

export default memo(InlineBannerTemplate);
